<template>
  <!-- 面包屑 -->
  <a-breadcrumb>
    <a-breadcrumb-item>结算中心</a-breadcrumb-item>
    <a-breadcrumb-item>每日分成数据</a-breadcrumb-item>
  </a-breadcrumb>
  <!-- 功能条 -->
  <div class="tool-view">
    <!-- 日期： -->
    <span class="tool-title">日期：</span>
    <a-range-picker
      v-model:value="dateRange"
      :format="dateFormat"
      :allowClear="false"
      @change="onDateChange"
    />
    <!-- 弹簧 -->
    <div style="flex: 1;"></div>
    <!-- 操作 -->
    <a-button @click="touchDownload">导出数据</a-button>
  </div>
  <!-- 列表 -->
  <a-table
    class="table-view"
    :data-source="dataSource"
    :columns="columns"
    :row-key="record => record.id"
    :pagination="pagination"
    :loading="isLoading"
    @change="handleTableChange"
  >
    <!-- 自定义行内容 -->
    <template #bodyCell="{ column, record }">
      <template v-if="column.key === 'total_amount' || column.key === 'total_income_amount' || column.key === 'refund_amount' || column.key === 'divideable_amount'">
        {{ $pub.KEEP_NUMBER_DECIMAL((record[column.key] || 0) / 100, 2, false, true) }}
      </template>
      <template v-if="column.key === 'cash_rate'">
        {{ `${record[column.key] || 0}%` }}
      </template>
    </template>
  </a-table>
</template>

<script setup>
import { onBeforeMount, ref, reactive } from 'vue'
import { message } from 'ant-design-vue'
import dayjs from 'dayjs'
import * as Ex from "@netjoy/excelex"
import { cashDayList } from '@/api/operate'
import Pub from '@/utils/public'

// 加载
let isLoading = ref(false)
// 日期范围
let dateRange = ref([dayjs().subtract(30, 'day'), dayjs()])
// 日期format
const dateFormat = 'YYYY-MM-DD'
// 数据源
let dataSource = ref([])
// 分页信息
let pagination = reactive({
  total: 0,
  current: 1,
  pageSize: 10,
  showSizeChanger: true
})
// 列数据源
let columns = ref([
  {
    title: '日期',
    dataIndex: 'data_date',
    key: 'data_date'
  },
  {
    title: '充值金额',
    dataIndex: 'total_amount',
    key: 'total_amount'
  },
  {
    title: '用户退款',
    dataIndex: 'refund_amount',
    key: 'refund_amount'
  },
  {
    title: '可分成金额',
    dataIndex: 'divideable_amount',
    key: 'divideable_amount'
  },
  {
    title: '分成比例',
    dataIndex: 'cash_rate',
    key: 'cash_rate'
  },
  {
    title: '预计分成',
    dataIndex: 'total_income_amount',
    key: 'total_income_amount'
  }
])

// 钩子函数
onBeforeMount (() => {
  // 获取推广数据列表
  getList()
})

// 分页处理
function handleTableChange (p, filters, sorter) {
  pagination.current = p.current
  pagination.pageSize = p.pageSize
  getList()
}

// 切换日期
function onDateChange () {
  // 设置分页
  pagination.current = 1
  // 数据
  getList()
}

// 获取推广数据列表
function getList () {
  isLoading.value = true
  const params = {
    start_time: dateRange.value ? `${dateRange.value[0].format(dateFormat)}` : undefined,
    end_time: dateRange.value ? `${dateRange.value[1].format(dateFormat)}` : undefined,
    page: pagination.current,
    page_size: pagination.pageSize
  }
  cashDayList(params).then(res => {
    isLoading.value = false
    const { code, data, msg } = res
    if (code === 0) {
      dataSource.value = data.data
      pagination.total = data.total
    } else {
      message.error(res.message || msg)
    }
  })
}

// 点击导出数据
function touchDownload () {
  isLoading.value = true
  const params = {
    start_time: dateRange.value ? `${dateRange.value[0].format(dateFormat)}` : undefined,
    end_time: dateRange.value ? `${dateRange.value[1].format(dateFormat)}` : undefined,
    page: 1,
    page_size: 100000
  }
  cashDayList(params).then(res => {
    isLoading.value = false
    const { code, data, msg } = res
    if (code === 0) {
      const list = data.data
      if (list.length) {
        const columns = [
          {
            name: '日期',
            field: 'data_date'
          },
          {
            name: '充值金额',
            field: 'total_amount'
          },
          {
            name: '用户退款',
            field: 'refund_amount'
          },
          {
            name: '可分成金额',
            field: 'divideable_amount'
          },
          {
            name: '分成比例',
            field: 'cash_rate'
          },
          {
            name: '预计分成',
            field: 'total_income_amount'
          }
        ]
        const sheets = [
          {
            data: list,
            columns: columns
          }
        ]
        Ex.ex(sheets, function (item, field, json, sheetIndex, row, col, rowCount, colCount) {
          // row=0 为表头
          if (row != 0) {
            if (field === 'total_amount' || field === 'total_income_amount' || field === 'refund_amount' || field === 'divideable_amount') {
              item.data = Pub.KEEP_NUMBER_DECIMAL(item.data / 100, 2, false, true)
              return item
            } else if (field === 'cash_rate') {
              item.data = `${json.cash_rate || 0}%`
              return item
            }
          }
          // 无数据兼容
          // item.data = item.data || 0
          return item
        },  `结算中心-每日分成数据`)
      } else {
        message.error('还没有可导出的内容')
      }
    } else {
      message.error('导出失败')
    }
  }).catch(() => {
    message.error('导出失败')
  })
}
</script>

<style scoped>
.tool-view {
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 30px;
}
.table-view {
  margin-top: 30px;
}
.tool-title:not(:first-child) {
  margin-left: 16px;
}

</style>